import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Transaction } from '../models/Transaction';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiUrl = 'https://prod-44.eastus.logic.azure.com:443/';
  addTransactionUrl = this.apiUrl + 'workflows/0fa9e1e7810a4db6b71b0fe8d53091ba/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=psbSAr6uXw9Fcjm2yzxBqx_nE9ejnQqsDgtjF0sBztQ';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  /** POST: add transaction to the server */
  addTransaction (transaction: Transaction): Observable<Transaction> {
    return this.http.post<Transaction>(this.addTransactionUrl, transaction, this.httpOptions)
      .pipe(
        // catchError(this.handleError('addTransaction', transaction))
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }
}
