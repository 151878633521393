import { Component, OnInit } from '@angular/core';
import { Transaction } from '../models/Transaction';
import { ApiService } from '../services/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-transaction-form',
  templateUrl: './transaction-form.component.html',
  styleUrls: ['./transaction-form.component.scss']
})
export class TransactionFormComponent implements OnInit {
  public isSubmitted = false;
  public transaction: Transaction;

  constructor(private apiService: ApiService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.resetForm();
  }

  onSave(): void {
    // validate values
    if(!this.transaction.amount ||
      this.transaction.amount <= 0) {
        this.openSnackBar('Please enter an amount greater then $0.');
        return;
    }

    this.isSubmitted = true;

    // build transaction
    this.transaction.datetime = new Date().toLocaleString().replace(',','');
    let adjustment = this.transaction.amount;

    if(this.transaction.operation !== 'add'){
      adjustment = adjustment * -1;
    }

    // save transaction
    const sendTransaction: Transaction = Object.assign({}, this.transaction, {amount: adjustment});

    this.apiService.addTransaction(sendTransaction).subscribe(transaction => this.transactionUpdateSuccessful(sendTransaction), error => this.transactionUpdateError(error));
  }

  resetForm(): void {
    this.transaction = new Transaction();
    this.transaction.operation = 'add';
    this.isSubmitted = false;
  }

  transactionUpdateSuccessful(transaction): void {
    this.openSnackBar(`You saved $${transaction.amount}!`, 2000);

    this.resetForm();
  }

  transactionUpdateError(error): void {
    this.isSubmitted = false;

    console.log('Update failed');
    console.log(error);

    this.openSnackBar('Something went wrong! Please try again.');
  }

  openSnackBar(message: string, inDuration?: number) {
    if(inDuration) {
      this.snackBar.open(message, null, {duration: inDuration});
      return;
    }

    // Force user to dismiss manually
    this.snackBar.open(message, 'Dismiss');
  }

}
